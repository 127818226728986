import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"6mx-profilozas"} />
		<Helmet>
			<title>
				6MX Profilozás - Online Tanfolyam
			</title>
			<meta name={"description"} content={"Ismerd fel a másokban mélyen rejlő érzelmeket, elrejtett félelmeket, bizonytalanságokat és igényeket, mindezt akár hat percen belül."} />
			<meta property={"og:title"} content={"Sikeres Kommunikáció - Sziklay Bence"} />
			<meta property={"og:description"} content={"Mi van, ha az emberi agynak vannak kiskapui, amelyek lehetővé teszik, hogy befolyásoljanak minket? Egyikünk sem szeretné elhinni, hogy ennyire kiszolgáltatottak vagyunk a minket körülvevő világnak, pedig sajnos igen."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/mesterkulcs.webp?v=2024-03-07T19:53:21.189Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
		</Helmet>
		<Components.GlobalHeaderNavBar overflow-x="hidden" overflow-y="hidden">
			<Override slot="link2" />
		</Components.GlobalHeaderNavBar>
		<Section
			padding="112px 0 45px 0"
			background="--color-brandSecondary"
			md-padding="96px 0 0px 0"
			overflow-x="hidden"
			overflow-y="hidden"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--brandLight"
					font="--lead"
					lg-text-align="center"
					sm-font="--lead"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="100%"
					opacity="0.6"
					hyphens="manual"
				>
					6MX - 6 PERC RÖNTGEN PROFILOZÁS
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--brandLight"
					font="--headline1"
					lg-text-align="center"
					sm-font="--headline2"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="100%"
				>
					Ismerd meg az emberek pszichológiai mozgatórugóit!
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--brandLight"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Mi van, ha az emberi agynak vannak kiskapui, amelyek lehetővé teszik, hogy befolyásoljanak minket? Egyikünk sem szeretné elhinni, hogy ennyire kiszolgáltatottak vagyunk a minket körülvevő világnak, pedig sajnos igen.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Components.CtaPopUp sm-width="100%" margin="10px 15px 0px auto">
						<Override slot="Button Open" sm-width="100%">
							Értesítést kérek amint elérhető
						</Override>
						<Override slot="Popup :closed" />
						<Override slot="Content" />
						<Override slot="Wrapper" />
						<Text margin="0px 0px 0px 0px" font="--lead">
							Értesítünk amint előrendelhető, illetve elérhető lesz a tanfolyam
							<br />
							Kérdésseddel bátran fordulj hozzánk elérhetőségeink bármelyikén.
						</Text>
						<Components.MailChimpSubscribe margin="0px auto 0px auto" listId="869da1a2b1" tagId="6322795">
							<Override slot="button" margin="0px auto 0px auto" />
						</Components.MailChimpSubscribe>
					</Components.CtaPopUp>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/humandmindspringsandgearsV2.png?v=2023-12-14T13:58:42.105Z"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
					srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/humandmindspringsandgearsV2.png?v=2023-12-14T13%3A58%3A42.105Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/humandmindspringsandgearsV2.png?v=2023-12-14T13%3A58%3A42.105Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/humandmindspringsandgearsV2.png?v=2023-12-14T13%3A58%3A42.105Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/humandmindspringsandgearsV2.png?v=2023-12-14T13%3A58%3A42.105Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/humandmindspringsandgearsV2.png?v=2023-12-14T13%3A58%3A42.105Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/humandmindspringsandgearsV2.png?v=2023-12-14T13%3A58%3A42.105Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/humandmindspringsandgearsV2.png?v=2023-12-14T13%3A58%3A42.105Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			background="--color-brandSecondary"
			md-padding="96px 0 0px 0"
			overflow-x="hidden"
			overflow-y="hidden"
			padding="24px 0 75px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text margin="8px 0px 0px 0px" font="--base" color="--brandLight" sm-text-align="left">
						Készen állsz arra, hogy hozzáférj az elme titkos kódjaihoz? Ugyanezeket a hozzáférési kódokat az Amerikai Hadsereg Pszichológiai Műveletek Parancsnoksága "veszélyesnek" nevezte.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text margin="8px 0px 0px 0px" font="--base" color="--brandLight" sm-text-align="left">
						Világvezetők arra alkalmazzák ezeket a módszereket, hogy stratégiailag befolyásolják a nemzetközi diplomáciát és irányítsák a közvéleményt saját céljaik elérése érdekében.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text margin="8px 0px 0px 0px" font="--base" color="--brandLight" sm-text-align="left">
						Ezek azok a technikák, amelyeket a legbefolyásosabb gondolatvezérek és döntéshozók használnak arra, hogy irányítsák a döntéseidet.
					</Text>
				</Box>
			</Box>
			<Box
				width="80%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				margin="35px auto 0px auto"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text margin="8px 0px 0px 0px" font="--base" color="--brandLight" sm-text-align="left">
						Nemsokára Te is hozzá fogsz jutni ezekhez a kódokhoz, amelyek számodra is kinyitják az emberi elme eddig ismeretlen területeit.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text margin="8px 0px 0px 0px" font="--base" color="--brandLight" sm-text-align="left">
						Amit ezekkel teszel, az már csak rajtad múlik, de ne feledd: szárnyalni csak másokat emelve lehet!{"\n"}
					</Text>
				</Box>
			</Box>
		</Section>
		<Section background="--color-brandSecondary">
			<Image src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13:45:38.514Z" display="block" srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13%3A45%3A38.514Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13%3A45%3A38.514Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13%3A45%3A38.514Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13%3A45%3A38.514Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13%3A45%3A38.514Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13%3A45%3A38.514Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13%3A45%3A38.514Z&quality=85&w=3200 3200w" sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw" />
		</Section>
		<Components.FooterGlobal overflow-x="hidden" overflow-y="hidden">
			<Override slot="linkBox" />
			<Override slot="linkBox2" />
			<Override slot="linkBox1" />
		</Components.FooterGlobal>
		<Components.QuarklycommunityKitBackToTop overflow-x="hidden" overflow-y="hidden">
			<Override slot="Icon" color="--brandCta" />
		</Components.QuarklycommunityKitBackToTop>
	</Theme>;
});